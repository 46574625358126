<template>
  <div class="content-main-top-container">
    <div class="container">
      <div class="title">
        호스팅 이용약관
      </div>
      <div v-html="policy.hosting" class="sun-editor-editable content"></div>
    </div>
  </div>
</template>
<script>
  export default {
    name: "hosting",
    data() {
      return {
        policy: {}
      }
    },
    created () {
      this.$axios.get('public/service/policy').then(res => {
        if(res.status === 200) {
          this.policy = res.data.policy;
        }
      });
    },
    methods: {
    }
  }

</script>

<style scoped>
  .title {
    font-size: 24px;
    color: #000000;
    text-align: center;
    padding: 37px;
  }

  .content {
    padding: 0 20px;
    padding-bottom: 70px;
    white-space: pre-line;
  }

</style>
